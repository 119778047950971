<template>
  <div class="container col-start-start">
    <headBar ref="headerHeight" title="我的卡券" left-arrow @click-left="newAppBack"></headBar>
    <div class="block flex-auto col-start-start">
      <div class="top flex-none row-between-center">
        <p class="f22">本页面卡券数据来源于您第三方平台购买的卡券商品，可点击兑换至APP中进行使用。</p>
        <img @click="goExchangeCenter" src="https://img.chaolu.com.cn/ACT/coupon-exchange/exchange-button.png" alt="">
      </div>
      <div v-if="couponList.length" class="list-area flex-auto">
        <div v-for="item in couponList" :key="item.cdKey" class="item row-between-center">
          <div class="tag f20 fw6 row-center-center">{{ item.channelTypeName }}</div>
          <div class="left">
            <p class="f28 black fw6 ellipsis-2">{{ item.thirdSkuName }}</p>
            <div class="p2 black f22 row-start-center">
              <p class="f22">兑换码：{{ item.cdKey }}</p>
              <div @click="copy(item.cdKey)" class="copy f18 row-center-center flex-none">复制</div>
            </div>
            <p @click="openRule(item)" class="p3 f22">
              <span :class="[item.isRedeem ? '' : 'red']">{{ item.isRedeem ? item.endTime : item.startTime }}{{ item.isRedeem ? '到期' : '后可兑换' }}</span>
              &nbsp;规则<van-icon name="arrow" />
            </p>
          </div>
          <div class="col-center-center flex-none">
            <p class="price f32 fw6">￥<span class="BebasNeueBold">{{ item.cashValue }}</span></p>
            <!-- <p v-else class="price f32 fw6"><span class="BebasNeueBold">{{ item.daysFromRedeemTime }}</span> 天</p> -->
            <div @click="goExchange(item)" :class="['btn', 'f22', 'fw6', 'row-center-center', item.isRedeem ? '' : 'disabled']">立即兑换</div>
          </div>
        </div>
      </div>
      <div v-else class="empty flex-auto col-start-center">
        <img src="https://img.chaolu.com.cn/ACT/double11-2022/warm-up/empty.png" alt="">
        <p class="f28">暂无卡券</p>
      </div>
    </div>
    <!-- 确认兑换 -->
    <van-popup v-model="isExchangeShow" :safe-area-inset-bottom="true" @close="close" position="bottom" :lock-scroll="true" :closeable="true">
      <div class="exchange-content">
        <div class="title f36 fw6">确认兑换</div>
        <div class="pay-item f24 row-between-center">
          <div class="left">兑换商品</div>
          <div class="left fw6">{{ exchangeItem.thirdSkuName }}</div>
        </div>
        <div class="pay-item f24 row-between-center">
          <div class="left">购买渠道</div>
          <div class="left fw6">{{ exchangeItem.channelTypeName }}</div>
        </div>
        <div class="pay-item f24 row-between-center">
          <div class="left">兑换码</div>
          <div class="left fw6">{{ exchangeItem.cdKey }}</div>
        </div>

        <div @click="exchange" class="pay-btn row-center-center f32 fw6">确认兑换到APP中使用</div>
      </div>

    </van-popup>
    <!-- 兑换成功 -->
    <!-- <van-popup v-model="isSuccessShow" position="bottom" safe-area-inset-bottom class="pop-success">
      <div class="col-start-center">
        <img src="https://img.chaolu.com.cn/ACT/sell-membership-2022/success-icon.png" alt="" class="icon">
        <p class="f32 fw6">您的卡券已兑换成功!!</p>
        <div @click="goMyCardBag" class="button f28 fw6 row-center-center">去查看</div>
      </div>
    </van-popup> -->
    <!-- 规则 -->
    <van-popup v-model="isRuleShow" position="center" class="popsure">
      <div class="sure-title fw6 f36">使用规则</div>
      <div class="sure-content f24">{{ exchangeItem.thirdPartyRule }}</div>
      <div class="sure-sub f32 fw6" @click="isRuleShow = false">我知道了</div>
    </van-popup>

    <confirm type="1" :showConfirm="isSuccessShow" title="" text="您的卡券已兑换成功！" :btnTextArr="['继续兑换', '去查看']" @firstBtn="isSuccessShow = false" @secondBtn="go" />
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, appOpenWeb, gotoAppPage, goMyVoucher, goMyClassBag } from '@/lib/appMethod'
import headBar from '@/components/app/headBar'
import confirm from '@/components/confirm/confirm'
import wx from 'weixin-js-sdk'

export default {
  mixins: [userMixin],
  components: {
    headBar, confirm
  },
  data() {
    return {
      couponList: [], // 兑换列表
      exchangeItem: {}, // 兑换的对象

      isExchangeShow: false,
      isSuccessShow: false,
      isRuleShow: false,
    }
  },
  async created() {
    await this.$getAllInfo(['userId', 'cityId', 'venueId'])
    initBack()

    this.getCouponList()
  },
  methods: {
    newAppBack,
    close() {
      this.isExchangeShow = false
    },
    goExchangeCenter() {
      if (this.appTypeStr === 'mini') {
        wx.miniProgram.navigateTo({
          url: '/pages/subPages/exchange/index'
        })
      } else {
        gotoAppPage(4)
      }
    },
    openRule(item) {
      this.exchangeItem = item
      this.isRuleShow = true
    },
    goExchange(item) {
      if (!item.isRedeem) return
      this.isExchangeShow = true
      this.exchangeItem = item
    },
    exchangeCheck() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/cdKey/efficacyCdKey', {
        token: this.token,
        userId: this.userId,
        cdKey: this.exchangeItem.cdKey,
        venueId: this.venueId
      }).then((res) => {
        this.$toast.clear()
        return true
      }).catch(() => {
        return false
      })
    },
    async exchange() {

      const r = await this.exchangeCheck()
      if (!r) return

      this.$axios.post(this.baseURLApp + '/cdKey/activateCdKey/v2', {
        token: this.token,
        userId: this.userId,
        cdKey: this.exchangeItem.cdKey,
        venueId: this.venueId
      }).then((res) => {
        this.getCouponList()
        this.isSuccessShow = true
        this.isExchangeShow = false
      })
    },
    copy(str) {
      this.$copyText(str).then((e) => {
        this.tips('已复制到剪贴板')
      }, (e) => {
        this.tips('复制失败')
      })
    },
    getCouponList() {
      this.$axios.post(this.baseURLApp + '/api/thirdGoodCoupon/query', {
        token: this.token,
        userId: this.userId,
      }).then((res) => {
        const r = res.data
        this.couponList = r
      })
    },


    go() {
      // const goodsTypeData = [
      //     { name: '课包', value: 10 },
      //     { name: '礼品卡', value: 12 },
      //     { name: '优惠券', value: 13 },
      //     { name: '私教权益卡', value: 24 },
      //     { name: '超鹿权益卡', value: 25 },
      //     { name: '泳具实物券', value: 20 },
      //     { name: '超鹿学院实物券', value: 21 },
      //     { name: '实体卡实物券', value: 22 }
      // ]
      switch (this.exchangeItem.targetSkuType) {
        case 10:
          goMyClassBag()
          break;
        case 12:
          appOpenWeb('', `${window.location.origin}/#/myGift/index?userId=1&token=1`)
          break;
        case 24:
          appOpenWeb('', `${window.location.origin}/#/month-card/my-card?userId=1&token=1&tab=2`)
          break;
        case 25:
          appOpenWeb('', `${window.location.origin}/#/month-card/my-card?userId=1&token=1&tab=1`)
          break;
        // case 13:
        // case 20:
        // case 21:
        // case 22:

        //   break;
        default:
          goMyVoucher()
          break;
      }

    }
  },
}
</script>
<style lang="less" scoped>
.container {
  height: 100vh;
  background: #F5F5F5;

  * {
    box-sizing: border-box;
  }

  .block {
    overflow: hidden;
    .top {
      width: 750px;
      height: 118px;
      padding: 0 36px;
      background-color: #fff;
      img {
        width: 180px;
        margin: 0 0 0 36px;
      }
    }

    .list-area {
      width: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding: 30px 32px;

      .item {
        width: 100%;
        background-color: #fff;
        position: relative;
        padding: 42px 24px 16px;
        border-radius: 4px;
        margin: 0 0 30px;
        .left {
          max-width: 64%;
        }
        .tag {
          padding: 0 11px;
          height: 32px;
          background: #FF683E;
          color: #fff;
          border-radius: 4px 0px 4px 0px;
          position: absolute;
          left: 0;
          top: 0;
        }

        .p2 {
          margin: 20px 0 0;

          .copy {
            padding: 0 8px;
            height: 28px;
            background: #FFFFFF;
            border: 0.5px solid #000000;
            border-radius: 8px;
            margin: 0 0 0 12px;
          }
        }

        .p3 {
          margin: 16px 0 0;
          color: #3C454E;

          span {
            &.red {
              color: #F03C18;
            }
          }
        }

        .btn {
          width: fit-content;
          padding: 0 20px;
          height: 42px;
          background: #FFDE00;
          border-radius: 21px;
          margin: 8px 0 0;

          &.disabled {
            background: #E8E8E8;
          }
        }

        .price {
          span {
            font-size: 66px;
          }
        }
      }
    }

    .empty {
      width: 100%;
      color: #6C727A;
      padding: 228px 0 0;

      img {
        width: 320px;
        margin: 0 0 12px;
      }
    }
  }

  .pop-success {
    width: 100%;
    height: 100vh;
    color: #242831;
    background-color: #fff;
    padding: calc(100px + env(safe-area-inset-top)) 32px 0;

    .icon {
      width: 88px;
      margin: 32px 0 0;
    }

    p {
      margin: 40px 0 0;
    }

    .button {
      width: 426px;
      height: 88px;
      background: #FFDE00;
      border-radius: 8px 8px 8px 8px;
      margin: 120px 0 0;
    }
  }


  .exchange-content {
    padding: 0 32px;
    box-sizing: border-box;
    overflow-y: scroll;

    .pay-btn {
      width: 686px;
      height: 96px;
      background: #FFDE00;
      border-radius: 8px;
      margin: 124px 0 24px;
    }

    .title {
      color: #242831;
      padding: 28px 0 24px;
    }

    .pay-type {
      padding: 72px 0 8px;
    }

    .pay-item {
      margin-top: 28px;

      .s2 {
        color: #F03C18;
      }
    }
  }

  .popsure {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;

    .sure-title {
      color: #242831;
      text-align: center;
    }

    .sure-content {
      color: #3C454E;
      line-height: 1.6;
      margin: 36px 0 48px;
      max-height: 40vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      span {
        color: #F03C18;
      }
    }

    .sure-sub {
      border-radius: 8px;
      background: #FFDE00;
      color: #000;
      line-height: 96px;
      text-align: center;
    }
  }
  .black {
    color: #000;
  }
}</style>

<template>
  <div class="page-data">
    <audio preload="auto" src="https://img.chaolu.com.cn/ACT/report-2023/abc.mp3" loop="loop" ref="musicPlay2"></audio>
    <div class="nav row-between-center" :style="`padding-top: ${barHeight}px;`">
      <van-icon @click="newAppBack" name="arrow-left" class="back-icon" :color="appTypeStr === 'mini' ? 'transparent' : '#000'" />
      <img @click="playMusic" :class="['music-icon', isMusicOn ? 'active' : '', appTypeStr === 'mini' ? 'mini' : '']" :src="`${imgUrl}${isMusicOn ? 'music_on' : 'music_off'}.png`" alt="">
    </div>
    <div v-if="pageShow" :class="['poster', hasStart ? 'fade' : '', appTypeStr === 'mini' ? 'mini' : '']">
      <img @click="start" class="button" :src="`${imgUrl}start-button.png`" alt="" />
    </div>
    <img v-show="arrowShow" class="up-arrow" src="https://img.chaolu.com.cn/ACT/report-2023/up-arrow.png" alt="">
    <swiper v-if="swiperShow" :class="['swiper', sharePageShow ? 'fade' : '']" :options="swipeOption" ref="swiper">
      <!-- 2 -->
      <swiper-slide :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}2.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">

          <div class="s1 f26 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="0.3s">
              年末脚步悄然而至</p>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1.3s">
              而你从未放缓前进的步调</p><br>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="2.3s">
              新朋友不断加入，老朋友依旧在场</p>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="3.3s">
              别低估每一次坚持的力量，它会带给你巨大的能量</p><br>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="4.3s">
              回望<span class="white">2023</span>，在浸润汗水的时光里</p>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="5.3s">
              改变，发生在每一个你身上</p>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="6.3s">
              同聚超鹿运动场，一起回顾属于你的</p>
            <p class="f36 ani purple" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="7.3s" style="margin: 10px 0 0;">《2023年度运动报告》</p>
          </div>

        </div>
      </swiper-slide>
      <!-- 3 -->
      <swiper-slide :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}3.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">

          <div class="s2 f26 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="0s"><span class="f30 purple">{{ yearData.firstCheckIn }}</span></p>
            <p class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1s">
              还记得我们第一次见面</p>
            <p class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="2s">
              一转眼已经过了<span class="f30 purple">{{ yearData.firstMeetingBetweenDay }}</span>天</p>
            <br>
            <p v-if="yearData.latestCheckOutDay" class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="3s">
              <span class="f30 purple">{{ yearData.latestCheckOutDay }}</span>是个特别的日子
            </p>
            <p v-if="yearData.latestCheckOutTime" class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="4s"><span class="f30 purple">{{ yearData.latestCheckOutTime }}</span>，你依然在馆</p>
            <p class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="5s">
              运动上瘾，真的不是说说而已</p>
          </div>

        </div>
      </swiper-slide>
      <!-- 4 -->
      <swiper-slide :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}4.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">

          <div class="s3 f26 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p3 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="0s">2023年
            </p>
            <p class="p3 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1s">
              你已累计进馆<span class="f30 purple"> {{ yearData.totalCheckInNum }} </span>次</p>
            <p class="p3 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="2s">累计运动<span class="f30 purple"> {{ yearData.totalSportTime }} </span>分钟</p>
            <br>
            <p class="p3 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="3s">
              当生活不再两点一线
            </p>
            <p class="p3 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="4s">
              才能探索出更多精彩</p>
            <p class="p3 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="5s">
              只管向前走</p>
            <p class="p3 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="6s">
              你走的每一步都是进步</p>
          </div>
        </div>
      </swiper-slide>
      <!-- 5 -->
      <swiper-slide :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}5.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">
          <div class="s4 f26 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p1 ani purple" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="0s">
              {{ yearData.mostCheckInVenueName }}
            </p>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1s">
              最常看到你的身影
            </p>

            <br>
            <p class="p1 f32 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="2s">
              过去的2023年<br>
            </p>
            <p class="p1 f32 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="3s">
              你一共打卡了<span class="purple">{{ yearData.totalMostCheckInVenueNum }}</span>次
            </p>
            <br>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="4s">
              熟悉的运动主场</p>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="5s">
              不断刷新的运动状态</p>
          </div>
        </div>
      </swiper-slide>
      <!-- 6 -->
      <swiper-slide v-if="yearData.totalFinishScheduleNum" :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}6.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">
          <div class="s5 f32 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p4 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="0s">
              2023年，你完成<span class="purple">{{ yearData.totalFinishScheduleNum }}</span>节团课
            </p>
            <p class="p4 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1s">
              累计消耗<span class="purple">{{ yearData.totalBurnCalories }}</span>卡路里
            </p>
            <p class="p4 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="2s">
              相当于消耗了<span class="purple">{{ yearData.totalBurnMilkTeaNum }}</span>杯奶茶的热量
            </p>
            <br>
            <p class="p4 f26 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="3s">
              所有的汗水
            </p>
            <p class="p4 f26 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="4s">
              闪耀着你蜕变的光芒
            </p>

          </div>

        </div>
      </swiper-slide>
      <!-- 7 -->
      <swiper-slide v-if="yearData.totalFinishScheduleNum" :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}7.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">
          <div class="s6 f32 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="0s">
              其中，
            </p>
            <p class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1s">
              <span class="purple">{{ yearData.mostAttendScheduleName }}</span>是你最爱的团课课程
            </p>
            <p class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="2s">累计打卡
              <span class="purple">{{ yearData.mostAttendScheduleNum }}</span>次
            </p>
            <br>
            <p v-if="yearData.mostAttendScheduleTeacherName" class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="3s"><span class="purple">{{ yearData.mostAttendScheduleTeacherName
            }}</span>教练是你的第一选择</p>
            <br>
            <p v-if="yearData.mostAttendScheduleTeacherName" class="p2 f26 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="4s">彼此成就，共同成长 </p>
            <p v-if="yearData.mostAttendScheduleTeacherName" class="p2 f26 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="5s">相互照亮同行的路</p>
          </div>

        </div>
      </swiper-slide>
      <!-- 8 -->
      <swiper-slide v-if="yearData.totalFinishPerNum" :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}8.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">

          <div class="s7 f32 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="0s">你一共完成<span class="purple">{{ yearData.totalFinishPerNum }}</span>节私教课</p>
            <p v-if="yearData.mostAttendPerTeacherName" class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1s">
              <span class="purple">{{ yearData.mostAttendPerTeacherName }}</span>教练是你蜕变路上的最佳拍档
            </p>
            <br>
            <p class="p1 f26 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" :swiper-animate-delay="`${yearData.mostAttendPerTeacherName ? '2s' : '1s'}`">汗水会给出答案</p>
            <p class="p1 f26 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" :swiper-animate-delay="`${yearData.mostAttendPerTeacherName ? '3s' : '2s'}`">达到你想不到的目标彼岸</p>
          </div>

        </div>
      </swiper-slide>

      <!-- 9 -->
      <swiper-slide v-if="yearData.totalFinishMicroNum" :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}9.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">
          <div class="s8 f32 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p2 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="0s">你一共完成了<span class="purple">{{ yearData.totalFinishMicroNum }}</span>节小班课</p>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1s">
              <span class="f30 purple">{{ yearData.finishMicro }}</span>课程是你的最爱
            </p>
            <br>
            <p class="p1 f26 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="2s">
              用热爱与坚持</p>
            <p class="p1 f26 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="3s">
              解锁你的无限可能</p>
          </div>
        </div>
      </swiper-slide>
      <!-- 10 -->
      <swiper-slide class="sle1" :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}10.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">
          <div class="s9 f26 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="0s">
              又一年温暖同行，携热爱共赴新程</p>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1s">
              回顾只需要几分钟，但你的蜕变值得被看见</p>
            <br>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="2s">
              这一年，你做得真的很好</p>
            <p class="p1 ani" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="3s">
              新的一年，让我们继续闪光，澎湃前行</p>
            <br>
            <p class="p1 ani f40 purple" swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="4s">
              与超鹿为伍，创造更多的回忆</p>
          </div>
        </div>

      </swiper-slide>

      <!-- 分享 -->
      <swiper-slide class="sle1" :class="[appTypeStr === 'mini' ? 'mini' : '']" :style="`background-image: url(${imgUrl}11.png)`">
        <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">
          <div class="s10 f26 fw6" :style="`margin-top: ${barHeight}px`">
            <p class="p1 f40">
              选择你的<span class="purple">运动精神</span>#</p>
            <br>
            <p class="p1">
              练出你的新年New Power</p>
          </div>

          <div class="zxj col-start-center">
            <img class="ticket-bg" src="https://img.chaolu.com.cn/ACT/report-2023/zxj.png" alt="" />
            <img src="https://img.chaolu.com.cn/ACT/report-2023/zxj-t.png" alt="" :class="['ticket', isAni ? 'ani' : '']">
            <img class="ticket-bg" src="https://img.chaolu.com.cn/ACT/report-2023/zxj-b.png" alt="" />
            <img :class="['zxj-light', isLightAni ? 'ani' : '']" src="https://img.chaolu.com.cn/ACT/report-2023/zxj-light.png" alt="">
          </div>

        </div>

      </swiper-slide>

    </swiper>

    <!-- 分享页 -->
    <div v-if="sharePageShow" :class="['share-page', appTypeStr === 'mini' ? 'mini' : '']">
    <!-- :style="`background-image: url(${imgUrl}flag_${flagIndex}.png)`" -->
      <div class="target-box">
        <div class="user-box row-start-center">
          <img :src="userInfo.headImg" alt="" class="avator">
          <div class="us">
            <p class="usp f26 fw6">{{ userInfo.nickName }}</p>
            <p class="f18">来到超鹿<span class="purple f22 fw6"> {{ yearData.firstMeetingBetweenDay }} </span>天</p>
          </div>
        </div>
        <img :src="`${imgUrl}flag_${flagIndex}.png`" alt="" class="flag">
        <div class="text-box f22">
          <p class="pt">燃烧我的卡路里，</p>
          <p class="pt fw6">今年累计消耗 <span class="f36 purple-2">{{ yearData.totalSportCalories }}</span> kcal</p>
          <p class="pt">别低估每一滴汗水的分量，它将带你乘风破浪！</p>
          <br>
          <p class="pt">一年有525600分钟，</p>
          <p class="pt fw6">今年累计在馆 <span class="f36 purple-2">{{ yearData.totalSportTime }}</span> 分钟</p>
          <p class="pt">有些事，值得我们多花点时间。</p>
        </div>
        <img @click="changeFlag" src="https://img.chaolu.com.cn/ACT/report-2023/change-button.png" alt="" class="change-button">
        <img @click="shareShow = true" src="https://img.chaolu.com.cn/ACT/report-2023/s-button.png" alt="" class="s-button">
      </div>
      <div class="buy-box row-center-center">
        <img @click="jump('sell-membership')" src="https://img.chaolu.com.cn/ACT/report-2023/card-2.png" alt="" class="buy-card">
        <img @click="jump('sell-membership/coach')" src="https://img.chaolu.com.cn/ACT/report-2023/card-1.png" alt="" class="buy-card">
      </div>
      <img @click="lookAgain" src="https://img.chaolu.com.cn/ACT/report-2023/refresh-button.png" alt="" class="refresh-button">
    </div>

    <!-- 未进馆 已领取 -->
    <div v-if="noEnter" class="poster get fw6">
      <div :class="['content', appTypeStr === 'mini' ? 'mini' : '']">
        <div class="s9" :style="`margin-top: ${barHeight}px`">
          <p class="p2 f28">2023年我们还没有相遇</p>
          <p class="p2 f28">期待您<span class="purple">2024</span>年第一次进馆</p>
          <br>
          <p class="p2 f40 purple">立下Super Flag</p>
          <p class="p2 f26">成为新年运动代言人</p>
          <img v-if="hasNewEquity" @click="jump('new-people/home')" class="button" :src="`${imgUrl}noenter-get.png`" alt="" />
          <img v-else @click="jumpGroup" class="button" :src="`${imgUrl}noenter-order.png`" alt="" />
        </div>
      </div>
    </div>

    <!-- 分享 -->
    <common-share :value="shareShow" :shareParams="shareParams" @close="shareSuccess" @share-success="shareSuccess" :shareItem="shareItem" :miniSharePoster="appTypeStr === 'mini'" :reload="true">
      <template slot="createDom">
        <!-- 需要绘制的dom 写这 -->
        <div class="share-poster col-start-center">
          <img src="https://img.chaolu.com.cn/ACT/report-2023/poster-bg.png" alt="" class="bg">


          <div class="target-box">
            <img src="https://img.chaolu.com.cn/ACT/report-2023/flag_bg.png" alt="" class="target-bg">
            <div class="user-box row-start-center">
              <img :src="userInfo.headImg" alt="" class="avator">
              <div class="us">
                <p class="usp f22 fw6">{{ userInfo.nickName }}</p>
                <p class="f16">来到超鹿<span class="purple f18 fw6"> {{ yearData.firstMeetingBetweenDay }} </span>天</p>
              </div>
            </div>
            <img :src="`${imgUrl}flag_${flagIndex}.png`" alt="" class="flag">
            <div class="text-box f18">
              <p class="pt">燃烧我的卡路里，</p>
              <p class="pt fw6">今年累计消耗 <span class="f28 purple">{{ yearData.totalSportCalories }}</span> kcal</p>
              <p class="pt">别低估每一滴汗水的分量，</p>
              <p class="pt">它将带你乘风破浪！</p>
              <br>
              <p class="pt">一年有525600分钟，</p>
              <p class="pt fw6">今年累计在馆 <span class="f28 purple">{{ yearData.totalSportTime }}</span> 分钟</p>
              <p class="pt">有些事，值得我们多花点时间。</p>
            </div>
            <img src="https://img.chaolu.com.cn/ACT/report-2023/jiaojuan.png" alt="" class="modify-img">
            <img :src="`data:image/png;base64,${userInfo.qrCode}`" alt="" class="qr-code">
          </div>

        </div>
      </template>
    </common-share>

  </div>
</template>
<script>
import { newAppBack, initBack, appOpenWeb, getBarHeight, gotoAppPage } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import commonShare from '@/components/commonShare'
import userMixin from '@/mixin/userMixin'
import * as swiperAni from '@/lib/swiper-animate'
import wx from 'weixin-js-sdk'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

const webUrl = `${window.location.origin}/#/superdeer-activity/report-2023/index`

export default {
  mixins: [userMixin],
  components: {
    swiper,
    commonShare,
    swiperSlide
  },
  data() {
    return {
      barHeight: 0,
      imgUrl: 'https://img.chaolu.com.cn/ACT/report-2023/',
      shareShow: false,
      shareItem: ['posterfriend', 'postermessage', 'save'],
      shareParams: {
        title: '超鹿年度运动报告',
        miniImage: 'https://img.chaolu.com.cn/ACT/report-2023/mini-share.png',
        path: '/pages/webView/index?webUrl=' + window.location.origin + '/#/superdeer-activity/report-2023/index',
        miniProgramType: "0", // 正式0，开发1，体验2
        url: 'url',
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
      },

      hasStart: false, // 开启年度报告
      swiperShow: false,
      // 用户信息
      userInfo: {
        nickName: '',
        headImg: '',
      },
      yearData: {}, // 年度数据
      hasNewEquity: false, // 是否领取过新人权益
      isLightAni: false,
      isAni: false,
      swipeOption: {
        // initialSlide: 8,
        direction: 'vertical',
        effect: 'fade',
        preventInteractionOnTransition: true,
        keyboard: true,
        speed: 500,
        shortSwipes: true,

        on: {
          init: function () {
            swiperAni.swiperAnimateCache(this) // 隐藏动画元素
            swiperAni.swiperAnimate(this) // 初始化完成开始动画
          },
          transitionEnd: function (i) {
            swiperAni.swiperAnimate(this) // 每个slide切换结束时也运行当前slide动画
          },
          reachEnd: () => {
            this.arrowShow = false
            this.isLightAni = true
            setTimeout(() => {
              this.isAni = true
            }, 1500);

            setTimeout(() => {
              this.sharePageShow = true
            }, 4500);
            setTimeout(() => {
              this.swiperShow = false
              // 放开触摸禁止
              document.querySelector('.page-data').removeEventListener('touchmove', this.onTouchMove, { passive: false })
            }, 6000);
          },
        },
        pageFrom: ''
      },
      isMusicOn: false,
      pageShow: true,
      sharePageShow: false,
      arrowShow: false,
      shareUserId: '',

      flagIndex: this.random(1, 18),
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.pageFrom = from.path
    })

  },
  computed: {
    swiper() {
      return this.$refs.swiper.swiper
    },
    // 未进馆
    noEnter() {
      const str = JSON.stringify(this.yearData)
      return (str === '{}' || str === 'null')
    },

  },
  watch: {
    isMusicOn(v) {
      v ? this.$refs.musicPlay2.play() : this.$refs.musicPlay2.pause()
    }
  },
  methods: {
    newAppBack,
    playMusic() {
      this.isMusicOn = !this.isMusicOn
      this.$refs.musicPlay2.play()
    },
    start() {

      this.hasStart = true

      // 未进馆
      if (this.noEnter) {
        // this.read()
        setTimeout(() => {
          this.pageShow = false
        }, 2000);
        return
      }

      setTimeout(() => {
        this.swiperShow = true
      }, 50);
      setTimeout(() => {
        this.pageShow = false
        this.arrowShow = true
      }, 2000);
    },
    getData() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        loadingType: 'spinner',
      })
      this.$axios
        .post(this.baseURLApp + '/user/data/2024/review', {
          token: this.token,
          userId: this.userId,
        })
        .then((res) => {
          this.yearData = res.data.checkInData || {}
          this.$toast.clear()
        })
    },
    getIsNew() {

      this.$axios
        .post(this.baseURLApp + '/invitation/userinvite/giftReceive', {
          token: this.token,
          userId: this.userId,
        })
        .then((res) => {
          this.hasNewEquity = res.data.giftReceive === 1 ? true : false
        })
    },
    // 二维码和用户信息
    initQr() {
      this.$axios
        .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
          token: this.token,
          userId: this.userId,
          width: 240,
          height: 240,
          webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
          scene: JSON.stringify({
            webUrl,
          }),
        })
        .then((res) => {
          this.userInfo = res.data
        })
    },
    shareSuccess() {
      this.shareShow = false

    },

    // read
    read() {
      this.$axios
        .post(this.baseURLApp + '/user/data/2023/review/read', {
          token: this.token,
          userId: this.userId,
        })
        .then((res) => { })
    },
    random(min, max) {
      return parseInt(Math.random() * (max - min + 1) + min, 10);
    },
    changeFlag() {
      this.flagIndex = this.random(1, 18)
    },
    // #/superdeer-activity/new-people/home?location=1"
    jump(path) {
      const p = `${this.locationUrl}/#/superdeer-activity/${path}?location=1`
      appOpenWeb('', p)
    },
    jumpGroup() {
      gotoAppPage(3)
    },
    lookAgain() {
      this.hasStart = false
      this.pageShow = true
      this.sharePageShow = false
      this.isAni = false
      this.isLightAni = false
      document.querySelector('.page-data').addEventListener('touchmove', this.onTouchMove, { passive: false })
    },
    miniShare() {
      const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/report-2023/index`)
      this.shareParams.path = `/pages/webView/index?webUrl=${uu}`

      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })
    },
    onTouchMove(e) {
      e.preventDefault()
    }
  },


  async created() {
    await this.$getAllInfo()
    initBack()

    this.getData()
    this.getIsNew()
    this.initQr()

    this.miniShare()
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState == "hidden") {
        this.isMusicOn = false
      } else {
        this.getIsNew()
      }
    })
    document.querySelector('.page-data').addEventListener('touchmove', this.onTouchMove, { passive: false })

    this.barHeight = getBarHeight() + 10
    console.log(this.barHeight);
  },
}
</script>
<style lang="less">
.page-data {
  * {
    box-sizing: border-box;
  }

  width: 100%;
  height: 100%;
  background-color: #F7A8A1;
  overflow: hidden;

  .nav {
    width: 100%;
    height: 100px;
    padding: 0 32px;
    position: fixed;
    top: calc(30px + constant(safe-area-inset-top));
    top: calc(30px + env(safe-area-inset-top));
    z-index: 998;

    .back-icon {
      font-size: 54px;
    }

    .music-icon {
      width: 60px;
      transform: translate(12px, 86px);

      &.mini {
        transform: translate(12px, 0px);
      }
    }
  }

  .poster {
    width: 100%;
    height: 100vh;
    background-image: url(https://img.chaolu.com.cn/ACT/report-2023/1.png);
    background-size: 100% auto;
    background-position: top;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 997;

    &.mini {
      background-position: 0 -180px;
    }

    .button {
      width: 358px;
      position: absolute;
      left: 213px;
      bottom: 250px;
      animation: pulse 2s forwards infinite;
    }

    &.fade {
      animation: ff 2.5s forwards;
    }

    &.get {
      z-index: 996;
      background-image: url(https://img.chaolu.com.cn/ACT/report-2023/noenter-bg.png);

      .button {
        position: relative;
        left: auto;
        bottom: auto;
        margin: 52px 0 0;
      }

      .p6 {
        text-align: center;
        font-family: PuHuiTi;
        font-style: italic;
        color: #fff;
        word-spacing: 4px;
      }
    }
  }

  .share-page {
    width: 100%;
    height: 100vh;
    background-image: url(https://img.chaolu.com.cn/ACT/report-2023/12.png);
    background-size: 100% auto;
    background-position: top;
    position: relative;
    padding: 128px 0 68px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &.mini {
      background-position: 0 -180px;
      padding: 0 0 68px;
    }

    .target-box {
      width: 100%;
      height: 1016px;
      background-image: url(https://img.chaolu.com.cn/ACT/report-2023/flag_bg.png);
      background-size: 100% auto;
      position: relative;

      .user-box {
        position: absolute;
        top: 163px;
        left: 115px;

        .avator {
          width: 73px;
          border-radius: 50%;
          margin: 0 18px 0 0;
        }

        .usp {
          margin: 0 0 6px;
        }
      }
      .flag {
        height: 125px;
        position: absolute;
        top: 287px;
        left: 107px;
      }
      .text-box {
        position: absolute;
        top: 486px;
        left: 147px;
        line-height: 38px;
        color: #000;
      }
    }

    .buy-box {
      margin: 12px 0 0;

      .buy-card {
        width: 321px;
        animation: pulse2 4s forwards infinite;
      }
    }
    .refresh-button {
      display: block;
      margin: 49px auto 0;
      width: 141px;
    }

    .change-button {
      width: 137px;
      position: absolute;
      top: 424px;
      left: 307px;
    }

    .s-button {
      width: 496px;
      position: absolute;
      bottom: 109px;
      left: 127px;
    }
  }

  .up-arrow {
    width: 53px;
    position: fixed;
    bottom: 104px;
    left: 349px;
    z-index: 998;
    animation: upAni 1.2s linear infinite;
  }

  .swiper {
    width: 100%;
    height: 100vh;
    background-size: 100% auto;
    background-position: center;
    overflow: hidden;

    position: relative;
    z-index: 1;

    &.fade {
      animation: ff 2.5s forwards;
    }

    .swiper-slide {
      background-size: 100% auto;
      background-position: top;
      background-repeat: no-repeat;

      &.mini {
        background-position: 0 calc(-176px - env(safe-area-inset-top));
      }

      .share-button {
        width: 140px;
        position: absolute;
        right: -2px;

        top: calc(180px + constant(safe-area-inset-top));
        top: calc(180px + env(safe-area-inset-top));
      }

      .go-button {
        position: absolute;
        width: 411px;
        right: 157px;
        bottom: calc(109px + constant(safe-area-inset-bottom));
        bottom: calc(109px + env(safe-area-inset-bottom));
        animation: pulse 2s forwards infinite;
      }
    }
  }

  .content {
    width: 100%;
    // background-color: rgba(255, 255, 255, .3);
    position: absolute;
    left: 0;
    top: 0;
    padding: calc(88px + env(safe-area-inset-top)) 0 0;

    &.mini {
      padding: 0;
    }

    .s1 {
      width: 100%;
      padding-top: 74px;
      text-align: center;

      img {
        width: 356px;
        margin: 16px 0 0;
      }
    }

    .s2 {
      padding: 74px 0 0 67px;
    }

    .s3 {
      padding: 68px 0 0 412px;
    }

    .s4 {
      padding: 68px 0 0 60px;
    }
    .s5 {
      padding: 108px 0 0 60px;
    }

    .s6 {
      padding: 88px 0 0 60px;
    }

    .s7,
    .s8,
    .s9 {
      text-align: center;
      padding: 98px 0 0;
    }
    .s8 {
      padding: 130px 0 0;
    }

    .s10 {
      padding: 98px 0 0 60px;
    }

    .p1,
    .p2,
    .p3,
    .h1 {
      color: #000;
    }

    .p1 {
      line-height: 46px;

    }

    .p2 {
      line-height: 52px;
    }

    .p3 {
      line-height: 42px;
    }
    .p4 {
      line-height: 56px;
    }

    .h1 {
      font-size: 68px;
      line-height: 1.6;
      color: #fff;
    }

  }
}

.zxj {
  margin: -28px 0 0 -12px;
  position: relative;

  .ticket-bg {
    width: 603px;

    &:nth-child(1) {
      position: relative;
      z-index: 2;
    }
  }

  .ticket {
    width: 499px;
    height: 317px;
    position: absolute;
    left: 142px;
    bottom: 109px;

    &.ani {
      animation: ticket 1.3s linear 1s;
      animation-fill-mode: forwards;
    }
  }

  .zxj-light {
    width: 381px;
    position: absolute;
    right: 0px;
    top: 8px;
    z-index: 3;
    opacity: 0;

    &.ani {
      animation: light .8s ease-out 1s;
      transform-origin: center;
      animation-fill-mode: forwards;
    }
  }
}

.share-img {
  width: 85%;
}

.white {
  color: #fff;
}

.purple {
  color: #BF00C1 !important;
}
.purple-2 {
  color: #890195 !important;
}

.mb-15 {
  margin: 0 0 15px;
}

.mb-35 {
  margin: 0 0 35px;
}

.max434 {
  max-width: 434px;
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse2 {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes ff {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  99% {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(2, 2, 2);
    opacity: 0;
    x-index: -1;
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 0;
    z-index: -1;
  }
}

@keyframes light {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes upAni {
  0% {
    transform: translateY(0px);
    opacity: 0;
  }

  30% {
    transform: translateY(-10px);
    opacity: 0;
  }

  60% {
    transform: translateY(-20px);
    opacity: 1;
  }

  100% {
    transform: translateY(-30px);
    opacity: 0;
  }
}

@keyframes ticket {
  0% {
    bottom: 30%;
  }

  100% {
    bottom: -27%;
    filter: drop-shadow(0 0 8px #FFF5BA);
  }
}

@bei: 0.8px;

.share-poster {
  box-sizing: border-box;
  position: relative;
  width: 600px;
  margin: 0 auto;
  .bg {
    width: 100%;
  }

  .target-box {
    width: 100%;
    position: absolute;
    top: 129px;
    left: 0;
    .target-bg {
      width: 100%;
    }
  }

  .user-box {
    position: absolute;
    top: @bei*160;
    left: @bei*115;

    .avator {
      width: @bei*73;
      border-radius: 50%;
      margin: 0 @bei*18 0 0;
    }


  }
  .flag {
    height: @bei*125;
    position: absolute;
    top: @bei*287;
    left: @bei*107;
  }
  .text-box {
    position: absolute;
    top: @bei*486;
    left: @bei*147;
    line-height: @bei*38;
    color: #000;
  }
  .modify-img {
    width: 208px;
    position: absolute;
    right: 0;
    top: 374px;
  }
  .qr-code {
    width: 61px;
    margin: 24px 0 0 481px;
  }

}</style>

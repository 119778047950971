<template>
  <div class="sb">
    <nav-bar headerTitle="榨脂教练推荐榜" ref="head" backColor="#fff" :showBack="true" :headerBack="newAppBack"></nav-bar>
    <img @click="goCoach(i)" v-for="item, i in picNum" :key="i" :src="`https://img.chaolu.com.cn/ACT/tuijianjiaolian202303/${city}/${city}_${ ('0' + (i + 1)).slice(-2) }.jpg`" alt="">

  </div>
</template>

<script>
import { getParam } from '@/lib/utils'
import navBar from '@/components/nav-bar/nav-bar'
import { newAppBack, gotoCoachDetail } from '@/lib/appMethod'

export default {
  components: {
    navBar
  },
  data() {
    return {
      city: getParam().city,

      ids: {
        'fz': [
          '',
          '551e8c42080445bbb853b2038da3d3d5',
          'c2fb5783ef97484f9d616df2d062ffc5',
          '783666934922088448',
          '829686077206827008',
          '7a5b3dbb45f6424ba2a05c09ed378ec1',
          '755382707256561664',
          'd413b32a4b824a0192e6c295bc5ee51b',
          'bbff1d12617748ff97911d276febeebe',
          'b29adc6612dc43f79689d484a3979740',
          '813799427293581312',
          '820000193226215424',
          '767736658580738048',
          '912298113950023680',
          '861580580502900736',
          'f5dd942a97ea4a448a0b2c4aef21feb7',
          '805831208121405440',
          '37258ee5e6e84c5d8dd2165be4dc8195',
          'd2b282a5f91e42838562c40798581278',
          '818858948030369792',
          ''
        ],
        'xm': [
          '',
          '017c393630d64aabb1905150156c30b3',
          '861567932184924160',
          '60e428951a0a435e927db1c23f3c3b42',
          '829013792116445184',
          '837667327519494144',
          '807939680195579904',
          '824235826425630720',
          '944198257041473536',
          '779384138091204608',
          '631790459246661632',
          '626775703150223360',
          '730407221166346240',
          ''
        ],
        'gz': [
          '',
          'be19013e21f348f792d6792a4b490370',
          '951889895020822528',
          '847473287691767808',
          '829300858880528384',
          '618087049976995840',
          '606864729534361600',
          '842049625446682624',
          '881971061669761024',
          '',
        ]
      }
    }
  },
  computed: {
    picNum() {
      switch (this.city) {
        case 'gz':
          return 10
        case 'xm':
          return 14
        default:
          return 21
      }
    }
  },
  methods: {
    newAppBack,
    goCoach(i) {
      if (!this.ids[this.city][i]) return
      gotoCoachDetail(this.ids[this.city][i])
    }
  },
}
</script>

<style lang="less" scoped>
.sb {
  width: 100%;
  img {
    width: 100%;
    display: block;
  }
}
</style>

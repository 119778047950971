<template>
  <div class="sb">
    <nav-bar headerTitle="领取小班课新人券" ref="head" :showBack="true" :headerBack="newAppBack"></nav-bar>
    <div class="poster col-start-center">
      <div class="button f36 fw6 row-center-center" @click="getVoucher">{{ buttonStatus }}</div>
    </div>
    <div class="info col-start-center">
      <p class="p1 f36 fw6">60元小班课优惠券（新人专享）</p>
      <p class="p2 f24">需为未体验过小班课新用户，其中使用同一登录账号、同一手机号码、同一终端设备号、同一支付账号或其他合理情形，均视为同一用户，且一个用户仅能领取一次并使用一张60元小班课优惠券。<br>
1、本券超鹿全门店小班课约课使用；<br>
2、本券不能与余额、企业卡、礼品卡叠加使用；<br>
3、取消订单后本券自动退还；<br>
4、本券限用一次，不提现，不找零。</p>
    </div>

    <van-popup v-model="isGetShow" :overlay="true" style="background-color: transparent" :close-on-click-overlay="false">
      <div v-if="isSuccess" class="confirm-box col-start-center">
        <div @click="appointment" class="button f36 fw6 row-center-center">约一节课</div>
      </div>

      <div v-else class="confirm-box got col-start-center">
        <p class="t1 f36 fw6">{{ receiveStatus }}</p>
        <div @click="appointment" class="button f36 fw6 row-center-center">约一节课</div>
      </div>

      <img @click="isGetShow = false" class="result-close"
        src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png" alt="">
    </van-popup>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
import navBar from '@/components/nav-bar-202210/index'
import {newAppBack, goMiniRecommendedCoach, gotoAppPage} from '@/lib/appMethod'

export default {
  mixins: [userMixin],
  components: {
    navBar
  },
  data() {
    return {
      isGetShow: false,
      detail: {},
      isSuccess: false,
      coachId: '', // teacherId会与mixin中重叠

      isNotNew: false,
    }
  },
  computed: {
    receiveStatus() {
      const s = this.detail.receiveStatus
      if (s === 'RECEIVED') return '您已领过券了，不可重复领取'
      return '非小班课新用户，无法领取'
    },

    buttonStatus() {
      const s = this.detail.receiveStatus
      if (s === 'RECEIVED') return '已领取'
      if (s === 'HAS_GONE') return '已抢光'
      if (s === 'FINISHED') return '已结束'
      if (s === 'NEW_USERS_OF_NON_MICRO_COURSE') return '暂不可领取'
      return '立即领取'
    }

  },
  async created() {
    await this.$getAllInfo()
    this.coachId = getParam().teacherId
    this.getInfo()
    if(this.$route.query.channelCode){
      this.$axios.post(this.baseURLApp + '/dataPoint/dataPoint', {
        userId: this.userId || 0,
        activityType: 234,
        activityId: 325,
        templateId: 1703,
        count: "0",
        amount: "0",
        ext1: this.$route.query.channelCode
      }).then((res) => {
        console.log('埋点成功', this.$route.query.channelCode)
      })
    }
  },
  methods: {
    newAppBack,

    getInfo() {
      this.$axios
        .post(this.baseURLApp + "/receive/activity/getActivityInfo", { userId: this.userId, token: this.token, hasWx: true })
        .then((res) => {
          this.detail = res.data

          if (this.detail.receiveStatus !== 'CAN_RECEIVE') {
            this.isGetShow = true
          }
        })

    },
    getVoucher() {

      if (this.detail.receiveStatus === 'CAN_RECEIVE') {
        this.$toast.loading()
        this.$axios
          .post(this.baseURLApp + "/receive/activity/receiveVoucher", { userId: this.userId, token: this.token, teacherId: this.coachId })
          .then((res) => {
            this.isSuccess = res.data
            this.isGetShow = true
            this.$toast.clear()
            this.getInfo()
          }).catch((res) => {
            this.getInfo()
          })
      } else {
        this.getInfo()
      }
    },

    async appointment() {
      if(this.$route.query.sourceChannel === 'wechatAd' || this.$route.query.channelCode) {
        gotoAppPage(8)
        return false
      }
      let has = await this.judgeHasSchedule()
      const list = has.data.list
      has = list && list.length && list[0].classs.length

      if (this.appTypeStr === 'mini') {
        if (has) {
          wx.miniProgram.navigateTo({
            url: `/pages/priPages/coachDetail/coachDetail?teacherId=${this.coachId}&autoOpenMini=1`
          })
        } else {
          goMiniRecommendedCoach()
        }
      } else {
        goMiniRecommendedCoach()
      }
    },
    // 判断是否有课
    judgeHasSchedule() {
      return this.$axios.post(this.baseURLApp + "/micro/userApp/class/teacherClassList", { userId: this.userId, teacherId: this.coachId })
    }
  },
}
</script>

<style lang="less" scoped>
.sb {
  width: 100%;
  min-height: 100vh;
  background-color: #EF7703;
  padding: 0 0 64px;

  * {
    box-sizing: border-box;
  }

  .poster {
    width: 100%;
    height: 1012px;
    background-size: 100%;
    background-image: url(https://img.chaolu.com.cn/ACT/get-mini-voucher/5.png);
    padding: 818px 0 0;

    .button {
      color: #fff;
      width: 420px;
      height: 96px;
      border-radius: 12px;
      background: linear-gradient(90deg, #FF7D34 0%, #FC5B01 100%);
    }
  }

  .info {
    width: 678px;
    background: linear-gradient(180deg, #FFF9F0 0%, #FCEDD8 100%);
    border-radius: 20px 20px 20px 20px;
    border: 2px solid #fff;
    margin: 16px auto;
    padding: 44px 32px 32px;

    .p1 {
      color: #BE5520;
    }

    .p2 {
      width: 100%;
      color: #5A0A00;
      margin: 28px 0 0;
      text-align: left;
    }
  }

  .confirm-box {
    width: 574px;
    height: 626px;
    background-size: 100%;
    margin: 0 auto;
    background-image: url(https://img.chaolu.com.cn/ACT/get-mini-voucher/6.png);
    padding: 440px 0 0;

    &.got {
      width: 574px;
      height: 558px;
      background-image: url(https://img.chaolu.com.cn/ACT/get-mini-voucher/3.png);
      padding: 196px 0 0;

      .t1 {
        color: #5A0A00;
      }

      .button {
        margin: 144px 0 0;
      }
    }

    .button {
      width: 420px;
      height: 96px;
      background: linear-gradient(90deg, #FF7D34 0%, #FC5B01 100%);
      margin: 24px 0 0;
      color: #fff;
      border-radius: 12px;
    }
  }

  .result-close {
    width: 60px;
    height: 60px;
    margin: 48px auto 0;
    display: block;
  }
}</style>
